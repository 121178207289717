// src/Modal.js

import React from "react";
import "./index.css"; // 팝업 스타일 정의

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>점검중 입니다</h2>
        {/* <button onClick={onClose}>닫기</button> */}
      </div>
    </div>
  );
};

export default Modal;
