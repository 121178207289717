import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./redux/store";
import "./i18n";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import persistedReducer from "./redux/reducers/index"; // 추가
import { persistStore } from "redux-persist"; // 추가
import { PersistGate } from "redux-persist/integration/react"; // 추가
import Modal from "./Modal"; // 추가한 모달 컴포넌트 불러오기

const persistor = persistStore(store);

const Root = () => {
  const [isModalOpen, setIsModalOpen] = useState(true); // 모달 상태 관리

  const closeModal = () => {
    setIsModalOpen(false); // 모달 닫기 함수
    window.close(); // 창 닫기
  };

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            {/* isModalOpen 상태에 따라 모달을 보여줍니다 */}
            <Modal isOpen={isModalOpen} onClose={closeModal} />
            {/* <App /> */}
          </Router>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

reportWebVitals();

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate persistor={persistor}>
//         <Router>
//           <App />
//         </Router>
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(//console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
